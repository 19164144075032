export const filterUniqueArray = arrayWithDuplicates => {
  const seenIds = new Set();
  const uniqueArray = arrayWithDuplicates.filter(item => {
    if (!seenIds.has(item.id)) {
      seenIds.add(item.id);
      return true;
    }
    return false;
  });

  return uniqueArray;
};
